export class LineDrawer {
    draw(context, particle, radius) {
        const shapeData = particle.shapeData;
        context.moveTo(-radius / 2, 0);
        context.lineTo(radius / 2, 0);
        context.lineCap = shapeData?.cap ?? "butt";
    }
    getSidesCount() {
        return 1;
    }
}
